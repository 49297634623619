
const NotFound = () =>{
    return(
        <div class="not-found-page">
            <div class="container-fluid d-flex justify-content-center align-items-center">
                <div class="img-con">
                    <img src="../img/page404.png" alt="Page Not Found" class="img-fluid" />
                </div>
            </div>
        </div>
    );
}

export default NotFound;