import React,{useEffect,useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {Navbar,Nav,NavItem,NavLink,NavbarBrand,Row,Col,} from 'reactstrap';
import {checkData} from '../ObjectExist'
import axios from 'axios';
import Swal from 'sweetalert2';

const TrackShipment = (props) => {    
    const {brandName} = useParams();
    const [brandDetail, setBrandDetail] = useState();
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    useEffect(()=>{
    const script = document.createElement("script");
    script.src = "https://www.logistify.in/js/trackcall.js";
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);
    
    merchantDefine();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const merchantDefine = () =>{
        setLoader(true);
        axios.post(`${process.env.REACT_APP_MERCHANT_BRAND_DETAIL}/${brandName}`)
        .then(function(res) {
        setLoader(false);
        if(res.data.length > 0){
            setBrandDetail(res.data[0]);
        }else{
            console.log("nodata")
            navigate("/page-not-found");
        }
        }).catch(function(err) {
            setLoader(false);
            console.log(err);
            Swal.fire({icon:"error",text:err,title:"Error"})
        }); 
    }

    const doTrack = ()=>{
        var MID = checkData(brandDetail) ? brandDetail.merchant_id : "";
        var num = document.getElementById("YQNum").value;
        var fieldtype = document.querySelector('input[name="radiodShadow"]:checked').value;
        console.log(fieldtype,num)
        if(num===""){
        alert("Enter your number.");
        return;
        }

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = 'YQV5.trackSingle({YQ_ContainerId:"YQContainer",YQ_Height:560,YQ_MID:'+MID+',YQ_Lang:"en",YQ_Num:"'+num+'",YQ_Type:"'+fieldtype+'"});';
        document.body.appendChild(s);
    }

    return (
    <>
    {checkData(brandDetail) ? 
    <div className="main-container">
        <Navbar expand="sm">
            <Nav className="justify-content-center w-100 position-relative flex-row" navbar >
                <NavItem className="tracking-logo">
                    <NavLink href="#" className='p-0'>
                        <img src="../img/liveTrackingLogo.png" alt="tracking" className="img-fluid" width="60"/>
                    </NavLink>
                </NavItem>
                {checkData(brandDetail) && 
                <NavItem>
                    <NavbarBrand href="#">
                        <img src={`${process.env.REACT_APP_IMAGE_MERCHANT}/${brandDetail.logo}`} alt="Brand Logo" className="img-fluid" />
                    </NavbarBrand>
                </NavItem>}
            </Nav>
        </Navbar>
        <div className="container-fluid">
            <Row className="align-items-center">
                <Col sm={6}>
                    <div className="p-3 p-md-5">
                        <h3>Order Tracking Made Easy</h3>
                        <p>Follow Your Orders As They Reach You</p>
                        <NavLink href="#track-shipment" className="btn btn-light shipment-btn p-2 px-4 mt-2 btn-hover d-inline-block">Track Shipment</NavLink>
                    </div>
                </Col>
                <Col sm={6} className="text-center">
                    <img src="../img/bgtrack.03308afd.png" alt="" className="img-fluid" />
                </Col>
            </Row>
        </div>  
        <div className="container-fluid">
            <div className="mx-2 mx-md-0 py-5">
                <div id="track-shipment" className="ordertrackSearch p-sm-5">
                    <div style={{textAlign: "center", width: "820px", maxWidth: "100%", margin: "auto", padding: "0.5rem",fontFamily:"RalewayThin,Helvetica, sans-serif"}}>
                        <h1 style={{display: "none", color: "#3F3D56", fontFamily: "Noto Sans, Arial, sans-serif", fontSize: "35px", margin: "0px"}}>Track Your Order</h1>
                        <div><p style={{margin: "0px", marginTop: "1rem"}}><span style={{fontSize: "14px",lineHeight: "1.5rem"}}>To track your order please enter your Tracking Number/Order ID/Mobile Number in the box below and Press “Track” Button. This was given to you in the receipt and in the order confirmation email you should have received.</span></p><p style={{margin: "0px"}}> </p></div>
                        <div class="mx-auto">
                            <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap", margin: "1rem 0 0.5rem 0"}}>
                                <fieldset style={{minWidth: "0",padding: "0",margin: "0",border: "0"}}>
                                <div style={{marginRight: "1.5rem"}}>
                                    <input type="radio" id="awbRadio" name="radiodShadow" defaultChecked value="awb_no" style={{boxSizing: "border-box",padding: "0"}} />
                                    <label for="awbRadio" className="ms-1" style={{display: "inline-block",marginBottom: "0.5rem"}}>Tracking No.</label>
                                </div>
                                </fieldset>
                                <fieldset style={{minWidth: "0",padding: "0",margin: "0",border: "0"}}>
                                <div style={{marginRight: "1.5rem"}}>
                                    <input type="radio" id="orderRadio" name="radiodShadow" value="order_no" style={{boxSizing: "borderBox",padding: "0"}} />
                                    <label for="orderRadio" className="ms-1" style={{display: "inline-block",marginBottom: "0.5rem"}}>Order No.</label>
                                </div>
                                </fieldset>
                                <fieldset style={{minWidth: "0",padding: "0",margin: "0",border: "0"}}>
                                <div style={{marginRight: "1.5rem"}}>
                                    <input type="radio" id="mobileRadio" name="radiodShadow" value="mobile_no" style={{boxSizing: "borderBox",padding: "0"}} />
                                    <label for="mobileRadio" className="ms-1" style={{display: "inline-block",marginBottom: "0.5rem"}}>Mobile No.</label>
                                </div>
                                </fieldset>
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: "center", margin: "0 0 0.5rem 0"}}>
                        <input type="text" id="YQNum" maxlength="50" placeholder="Enter Search Value." style={{lineHeight: "1.15",  textDecoration: "none", borderStyle: "solid", display: "inline-block", msoHide: "all", backgroundColor: "#ffffff", borderColor: "#0d0d0e", borderRadius: "5px", borderWidth: "1px", color: "#0d0d0e", fontFamily:"Noto Sans, Arial, sans-serif", fontSize: "18px", padding:"10px 5px", width: "428px", maxWidth: "100%", margin: "0 1rem 1rem 1rem"}}/>
                        
                        <div><input type="button" value="TRACK" onClick={()=>doTrack()} style={{backgroundColor: "#41aef3",borderColor: "#41aef3",border: "0", cursor: "pointer", borderRadius: "5px",color: "#ffffff", fontFamily: "Noto Sans, Arial, sans-serif", fontSize: "18px", padding: "0.5rem 1rem", margin: "0 0 1rem 0.5rem"}} /></div>
                    </div>
                </div>
                <div id="YQContainer"></div>
                </div>
            </div>
        </div>
        <div className="container-fluid mt-5 mb-3 ">
            <div className="my-5 merchant-con">
                <h3 className="text-center mb-4">Shipping Partners</h3>
                <div className="merchage-logos">
                    <Row className="justify-content-center">
                        <Col sm={3} xs={4} className="logo-1">
                            <div className="logo-con">
                                <img src="../img/merchnats/bluedart.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-2">
                            <div className="logo-con">
                                <img src="../img/merchnats/fedex.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-3">
                            <div className="logo-con">
                                <img src="../img/merchnats/delhivery.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-4">
                            <div className="logo-con">
                                <img src="../../img/merchnats/ecomexpress.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-5">
                            <div className="logo-con">
                                <img src="../img/merchnats/xpressbees.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-6">
                            <div className="logo-con">
                                <img src="../img/merchnats/dotzot.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-7">
                            <div className="logo-con">
                                <img src="../img/merchnats/ekart.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-8">
                            <div className="logo-con">
                                <img src="../img/merchnats/logistify.jpg" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-9">
                            <div className="logo-con">
                                <img src="../img/merchnats/shadowfax.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                        <Col sm={3} xs={4} className="logo-10">
                            <div className="logo-con">
                                <img src="../img/merchnats/udaanExpress.png" alt="" className="img-fluid w-100" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>            
        </div>
        <div className="container">
            <Row className="tracking-terms mt-5 mx-width-1200 mx-auto">
                <Col sm={6} md={4} className="my-4 d-flex align-items-start">
                    <div className="term-icon"><img src="../img/icons/calendar.png" alt="" /></div>
                    <div className="term-text">
                        <h5>Estimated Delivery Date</h5>
                        <p>You can expect to recieve your order by this date</p>
                    </div>
                </Col>
                <Col sm={6} md={4} className="my-4 d-flex align-items-start">
                    <div className="term-icon"><img src="../img/icons/orderReceive.png" alt="" /></div>
                    <div className="term-text">
                        <h5>Order Received </h5>
                        <p>Your order has been received by the courier company and courier tracking has been enabled</p>
                    </div>
                </Col>
                <Col sm={6} md={4} className="my-4 d-flex align-items-start">
                    <div className="term-icon"><img src="../img/icons/picked.png" alt="" /></div>
                    <div className="term-text">
                        <h5>Picked</h5>
                        <p>Your order has been picked up by the couries company mentioned in the Email.</p>
                    </div>
                </Col>
                <Col sm={6} md={4} className="my-4 d-flex align-items-start">
                    <div className="term-icon"><img src="../img/icons/delivery.png" alt="" /></div>
                    <div className="term-text">
                        <h5>In Transit</h5>
                        <p>Your order is moving through cities and werehouses</p>
                    </div>
                </Col>
                <Col sm={6} md={4} className="my-4 d-flex align-items-start">
                    <div className="term-icon"><img src="../img/icons/reached.png" alt="" /></div>
                    <div className="term-text">
                        <h5>Reached Destination</h5>
                        <p>Your order has arrived at the destination city</p>
                    </div>
                </Col>
                <Col sm={6} md={4} className="my-4 d-flex align-items-start">
                    <div className="term-icon"><img src="../img/icons/outForDelivery.png" alt="" /></div>
                    <div className="term-text">
                        <h5>Out For Delivery</h5>
                        <p>Couries executive is on its way to deliver your order at the address provided</p>
                    </div>
                </Col>
            </Row>
        </div>
        <footer>
            <div class="container-fluid px-0">
                <div class="p-3 p-md-4 px-md-5 d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center">
                    <div class="poweredBy-text me-3">
                        Powered By <span id="merchant_name">{checkData(brandDetail) && brandDetail.brand_name}</span>
                    </div>
                    <div class="copyright-text">
                        © Copyright 2022
                    </div>
                </div>
            </div>
        </footer>
    </div> : loader ? 
    <div className="loader-con d-flex justify-content-center align-items-center loader">
        <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div> : ""}
    </>
    );
}

export default TrackShipment;