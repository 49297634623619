import TrackShipment from './Components/TrackShipment';
import {Routes,  Route } from "react-router-dom";
import NotFound from './Components/NotFount';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<TrackShipment />}></Route>
        <Route path="/tracking/:brandName" element={<TrackShipment />}></Route>
        {/* <Route path="/page-not-found" element={<NotFound />}></Route> */}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;